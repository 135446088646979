$(() => {
  document.querySelector('#goup').addEventListener('click', Goup)

  function Goup() {
    document.querySelector('#navigation-menu').scrollIntoView({ block: "center", behavior: "smooth" });
  }

  //-----------
  const x = document.querySelector('#navigation-menu');
  const y = document.querySelector('.link-panel');
  const z = document.querySelector('.x');

  x.addEventListener("click", function () {
    y.classList.toggle('show');
  })

  z.addEventListener("click", () => {
    y.classList.toggle('show');
  })
})
